import React from "react";
import logo from "../assets/images/logo-final.png";

const Header = ({}) => {
  return (
    <header>
      <div className="logo-container">
        <div className="container list-container">
          <div className="header-navbar">
            <div className="list-inline inline-block">
              <a href="http://laffa.grandprize.sa">الرئيسية</a>
              {/* <NavLink to="/">اطلب لفه</NavLink> */}
            </div>
            <img src={logo} className="" />
          </div>
        </div>
        <div className="d-flex flex flex-column text-center header-text-container">
          <h2>اطلب لفه</h2>
        </div>
      </div>
    </header>
  );
};

export default Header;
