import React, {useState} from 'react';
import Loader from './spinner';

const Container = ({
  isLoading,
  children
}) => {
  return (
    <div className="container-fluid p-0 m-0">
      {isLoading ? (
        <div className="loader-container">
          <Loader isLoading={isLoading} className="loader" />
        </div>
      ) : children}
    </div>
  )
}

export default Container;