import Order from 'layouts/order';
import Pending from 'layouts/order-status';
import LeaveComment from 'layouts/leave-comment';
import RateOrder from 'layouts/rate-order';
import Success from 'layouts/success';

export default [
  {
    path: '/',
    Component: Order,
    isExact: true,
    isPublic: true
  },
  {
    path: '/order/status',
    Component: Pending,
    isExact: true,
    isPublic: true
  },
  {
    path: '/leave-comment',
    Component: LeaveComment,
    isExact: true,
    isPublic: true
  },
  {
    path: '/rate-order',
    Component: RateOrder,
    isExact: true,
    isPublic: true
  },
  {
    path: '/success',
    Component: Success,
    isExact: true,
    isPublic: true
  },
]