import React, { useEffect, useState, useContext } from "react";

import AppContext from "contexts/AppContext";
import Api from "utils/api";
import { useHistory } from "react-router";

const LeaveComment = () => {
  const history = useHistory();

  const {
    order: { id },
    cancelReasons,
  } = useContext(AppContext);

  const [order, setOrder] = useState({});

  const [comment, setComment] = useState("");

  const [cancelReason, setCancelReason] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      try {
        if (id) {
          const request = await Api({
            method: "get",
            url: `orders/order/${id}`,
          });
          if (request?.data?.order) setOrder(request?.data?.order);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getOrder();
  }, []);

  const postComment = async (e) => {
    try {
      e.preventDefault();
      const request = await Api({
        method: "post",
        url: "/cancel-reasons/create",
        body: {
          order_id: order.id,
          cancel_reason: cancelReason,
          comment,
        },
      });
      if (request?.data?.msg) history.push("success");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container">
      <div className="page-content" dir="rtl">
        <h3 className="text-right">تمنينا نخدمكم اليوم</h3>
        <form onSubmit={postComment} dir="rtl" className="mt-2">
          <div className="form-group">
            <label htmlFor="">
              إذا مافيها لقافة .. ليه لغيتوا الطلب , عشان نحسن خدمتنا
            </label>
            {/* <textarea rows="5" cols="10" type="text" className="form-control" placeholder="رايك يهمنا .." onChange={e => setComment(e.target.value)}></textarea> */}
            <select
              name="cancelreasons"
              id="cancelreasons"
              disabled={false}
              className="form-control"
              onChange={(e) => setCancelReason(e.target.value)}
            >
              <option>اختر السبب</option>
              {cancelReasons.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.reason}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">عندكم أي ملاحظات</label>
            <textarea
              rows="5"
              cols="10"
              type="text"
              className="form-control"
              placeholder="رايكم على العين والراس"
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group">
            <button
              onClick={postComment}
              disabled={!order?.client_name || !cancelReason}
              className="btn btn-primary"
              id="custom-primary-btn"
            >
              ارسل
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveComment;
