import React, {useContext} from 'react';

import AppContext from 'contexts/AppContext';

import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

// routes
import routes from './routes';

const Router = ({
}) => {

  const {order} = useContext(AppContext);

  return (
    <Switch>
      {
        routes.map(route => {
          return (
            <Route key={route.path} path={route.path} component={route.Component} exact={route.isExact} />
          )
        })
      }
      {
        order
        ? (
          <Route exact path={'/'}>
            <Redirect to='order/status' />
          </Route>
        ) : (
          <Route exact path={'/'}>
            <Redirect to='/' />
          </Route>
        )
      }
    </Switch>
  )
}

export default Router;