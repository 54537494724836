import React from 'react';
import Api from 'utils/api';
import moment from 'moment';
import Countdown from 'components/countdown';

export const CanceledOrderDetails = ({
  order,
  createNewOrder
}) => {

  return (
    <>
      <h3 className="text-right">آسفين .. طلبك اتلغى</h3>
      <div>
        <span>الاسم</span>
        <span>{order?.client_name}</span>
      </div>
      <div>
        <span>رقم الجوال</span>
        <span>{order?.mobile}</span>
      </div>
      <div>
        <span>عدد الركاب</span>
        <span>{order?.passengers}</span>
      </div>
      <div>
        <span>المدة</span>
        <span dir="rtl">{order?.duration} minutes</span>
      </div>
      <div>
        <span>حالة الطلب</span>
        <span>
          <span className={`status-container canceled`}>
            {order?.status}
          </span>
        </span>
      </div>
      <div>
        <span>نوع المركبة</span>
        <span>{order?.vehicle_type?.display_name}</span>
      </div>
      <div>
        <span>وين موقعك</span>
        <span>{order?.location?.display_name}</span>
      </div>
      <div>
        <span>الاجمالي</span>
        <span>{order?.total} S.R</span>
      </div>
      {
        order?.comment
        ? (
          <div>
            <span>سبب الالغاء</span>
            <span>{order?.comment?.message}</span>
          </div>
        ) : null
      }
      <div className="form-group d-flex justify-content-between">
        <button onClick={createNewOrder} className="btn btn-primary" id="custom-primary-btn">طلب جديد</button>
      </div>
    </>
  )
}