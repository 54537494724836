import React from 'react';
import Api from 'utils/api';
import moment from 'moment';
import Countdown from 'components/countdown';

export const PendingOrderDetails = ({
  order,
  refreshOrder,
  cancelOrderModal,
  toggleIsOrderTimedOut,
  isOrderTimedOut
}) => {

  const orderTimedOut = async () => {
    // FIXME: make order updated from parent component
    let request = await Api({
      method: 'get',
      url: `orders/order/${order?.id}`
    });
    if (request?.data?.order) {
      let {order: updated_order} = request.data;
      if (updated_order.status != order?.status)
        return refreshOrder();
      toggleIsOrderTimedOut(!isOrderTimedOut)
    }
  }

  return (
    <>
      <h3 className="text-right">طلبك قيد الانتظار</h3>
      <span className="text-right text-muted d-block">
          ارسلنا طلبك وبأسرع وقت بنرد عليك
      </span>
      <div>
        <span>الاسم</span>
        <span>{order?.client_name}</span>
      </div>
      <div>
        <span>رقم الجوال</span>
        <span>{order?.mobile}</span>
      </div>
      <div>
        <span>عدد الركاب</span>
        <span>{order?.passengers}</span>
      </div>
      <div>
        <span>المدة</span>
        <span dir="rtl">{order?.duration} minutes</span>
      </div>
      <div>
        <span>حالة الطلب</span>
        <span>
          <span className={`status-container pending`}>
            {order?.status}
          </span>
        </span>
      </div>
      <div>
        <span>نوع المركبة</span>
        <span>{order?.vehicle_type?.display_name}</span>
      </div>
      <div>
        <span>وين موقعك</span>
        <span>{order?.location?.display_name}</span>
      </div>
      <div>
        <span>الاجمالي</span>
        <span>{order?.total} S.R</span>
      </div>
      <div>
        <span>الوقت</span>
        <span>
          <Countdown
            orderTimeout={order?.timeout || moment().format('x')}
            onComplete={orderTimedOut}
          />
        </span>
      </div>
      <div className="form-group d-flex justify-content-between">
        <button onClick={refreshOrder} className="btn btn-primary" id="custom-primary-btn">تحديث الصفحة</button>
        <button onClick={cancelOrderModal} className="btn btn-danger">الغاء الطلب</button>
      </div>
    </>
  )
}