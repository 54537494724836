import React, { useState, useEffect, useContext } from "react";

import AppContext from "contexts/AppContext";

const Success = () => {
  const { redirectURL } = useContext(AppContext);

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds == 0) window.location.href = redirectURL;
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="container">
      <div className="page-content text-center" dir="rtl">
        <h3 className="text-center" style={{ color: "#61338c" }}>
          خيرها بغيرها ونتشرف بخدمتكم ..
        </h3>
        <hr />
        <span>ثواني وبنوديكم على الصفحة الرئيسية</span>
      </div>
    </div>
  );
};

export default Success;
