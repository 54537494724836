import axios from 'axios';

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL
});

baseApi.interceptors.request.use(async request => {
  return request
})

const Api = async ({
  method,
  url,
  body = {},
  headers = {},
}) => {

  try {
    const response = await baseApi[method](url, body, {headers:{'Content-Type': 'application/json'}})
    return response;
  } catch (e) {
    console.log('API ERROR OCCURED')
    throw e;
  }
}

export default Api;