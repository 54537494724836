import React from 'react';

const Modal = ({
  isShown,
  hideModal,
  children
}) => {
  return (
    <div className="custom-modal" style={{
      opacity: isShown ? 1 : 0,
      visibility: isShown ? 'visible' : 'hidden'
    }}>
      <div className="custom-modal-content">

        <div className="custom-modal-header">

          <button className="close-modal-btn" onClick={hideModal}>
            <i className="ion-close-round"></i>
          </button>
          
        </div>


        <div className="custom-modal-body">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;