import React from 'react';
import Api from 'utils/api';
import moment from 'moment';
import Countdown from 'components/countdown';

export const DoneOrderDetails = ({
  order,
  rateOrder
}) => {

  return (
    <>
      <h3 className="text-right">مركبتك في الطريق إليك</h3>
      <div>
        <span>الاسم</span>
        <span>{order?.client_name}</span>
      </div>
      <div>
        <span>رقم الجوال</span>
        <span>{order?.mobile}</span>
      </div>
      <div>
        <span>عدد الركاب</span>
        <span>{order?.passengers}</span>
      </div>
      <div>
        <span>المدة</span>
        <span dir="rtl">{order?.duration} minutes</span>
      </div>
      <div>
        <span>حالة الطلب</span>
        <span>
          <span className={`status-container approved`}>
            {order?.status}
          </span>
        </span>
      </div>
      <div>
        <span>نوع المركبة</span>
        <span>{order?.vehicle_type?.display_name}</span>
      </div>
      <div>
        <span>وين موقعك</span>
        <span>{order?.location?.display_name}</span>
      </div>
      <div>
        <span>الاجمالي</span>
        <span>{order?.total} S.R</span>
      </div>
      <div className="form-group d-flex justify-content-between">
        <button onClick={rateOrder} className="btn btn-primary" id="custom-primary-btn">تقييم الطلب</button>
      </div>
    </>
  )
}