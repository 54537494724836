import React from 'react';

export const OrderTimedOutComponent = ({
  extendOrder,
  discardOrder
}) => {

  return (
    <div className="d-flex flex-column timedout-order-container flex-grow-1">
      <div className="flex-1">
        <h3>للحين ما ردينا عليكم!! مرررره اسفين</h3>
      </div>
      <div>
        <p>
          تقدروا تستنونا شويات أو تلغوا الطلب :(
        </p>
      </div>
      <div className="custom-modal-footer d-flex justify-content-between p-0">
        <button onClick={extendOrder} className="btn btn-warning">
          ننتظر
        </button>
        <button onClick={discardOrder} className="btn btn-danger">
          إلغي الطلب
        </button>
      </div>
    </div>
  )

}

export const OrderCancelVerification = ({
  discardOrder
}) => {

  return (
    <div className="d-flex flex-column timedout-order-container flex-grow-1">
      <div className="flex-1">
        <h3>عساه خير</h3>
      </div>
      <div>
        <p>
        اكيد بتلغو الطلب؟ ما بتغيروا رأيكم؟
        </p>
      </div>
      <div className="custom-modal-footer d-flex justify-content-between p-0">
        <button onClick={discardOrder} className="btn btn-danger">
          إلغي الطلب
        </button>
      </div>
    </div>
  )
}

// export default OrderTimedOutComponent;