import React, { useContext, useEffect, useState, useReducer } from "react";

import AppContext from "contexts/AppContext";

import OrderDetails from "components/order-details";
import ReOrder from "components/re-order";
import Api from "utils/api";
import { remove, store } from "utils/localStorage";
import { useHistory } from "react-router";
import Countdown from "components/countdown";
import Modal from "components/modal";
import {
  OrderTimedOutComponent,
  OrderCancelVerification,
} from "components/order-timedout";
import {
  ApprovedOrderDetails,
  PendingOrderDetails,
  DoneOrderDetails,
  CanceledOrderDetails,
} from "components/order-status";

const PendingRequest = ({}) => {
  const {
    order: { id },
  } = useContext(AppContext);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const history = useHistory();

  const [order, setOrder] = useState({});

  const [toggleRefresh, setToggleRefresh] = useState(false);

  useEffect(() => {
    const getOrder = async () => {
      try {
        if (id) {
          const request = await Api({
            method: "get",
            url: `orders/order/${id}`,
          });
          console.log(request?.data?.order);
          if (request?.data?.order) setOrder(request?.data?.order);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getOrder();
  }, [toggleRefresh]);

  const refreshOrder = () => {
    setToggleRefresh(!toggleRefresh);
  };

  const discardOrder = async () => {
    try {
      let request = await Api({
        method: "patch",
        url: `orders/cancel/${id}`,
      });
      if (request?.data?.order) remove("order");
      history.push("/leave-comment");
    } catch (e) {
      console.log(e);
    }
  };

  const [isOrderTimedOut, toggleIsOrderTimedOut] = useState(false);

  const [isOrderCancelModalVisible, setIsOrderCancelModalVisible] =
    useState(false);

  const createNewOrder = () => {
    history.push("/");
  };

  const extendOrder = async () => {
    try {
      let request = await Api({
        method: "patch",
        url: `orders/extend/${order.id}`,
      });
      if (request?.data?.order) {
        store("order", request?.data?.order);
        refreshOrder();
        window.location.reload();
        toggleIsOrderTimedOut();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelOrderModal = () => {
    setIsOrderCancelModalVisible(!isOrderCancelModalVisible);
  };

  const cancelOrder = () => {
    cancelOrderModal();
    discardOrder();
  };

  const rateOrder = () => history.push("/rate-order", { order: order });

  return (
    <div className="container">
      <div className="page-content">
        <div className="order-details-container" dir="rtl">
          {/* {!id ? <ReOrder /> : null} */}
          {console.log(order?.status, id)}
          {order?.status == "pending" ? (
            <PendingOrderDetails
              order={order}
              refreshOrder={refreshOrder}
              cancelOrderModal={cancelOrderModal}
              toggleIsOrderTimedOut={toggleIsOrderTimedOut}
              isOrderTimedOut={isOrderTimedOut}
            />
          ) : order?.status == "approved" ? (
            <ApprovedOrderDetails
              order={order}
              refreshOrder={refreshOrder}
              cancelOrderModal={cancelOrderModal}
            />
          ) : order?.status == "canceled" ? (
            <CanceledOrderDetails
              order={order}
              createNewOrder={createNewOrder}
            />
          ) : order?.status == "done" ? (
            <DoneOrderDetails order={order} rateOrder={rateOrder} />
          ) : (
            <ReOrder />
          )}

          {/* Modals */}
          <Modal isShown={isOrderTimedOut} hideModal={toggleIsOrderTimedOut}>
            <OrderTimedOutComponent
              extendOrder={extendOrder}
              discardOrder={discardOrder}
            />
          </Modal>

          <Modal
            isShown={isOrderCancelModalVisible}
            hideModal={setIsOrderCancelModalVisible}
          >
            <OrderCancelVerification discardOrder={cancelOrder} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PendingRequest;

// {
//   id
//   ? <OrderDetails order={order} discardOrder={discardOrder} refreshOrder={refreshOrder} />
//   : <ReOrder />
// }
