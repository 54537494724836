import React, {
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import Api from "utils/api";
import Container from "components/container";
import { fetch, remove } from "utils/localStorage";
import moment from "moment";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // working hours
  const [isWithinWorkingHours, setIsWithinWorkingHours] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [orderTimeout, setOrderTimeout] = useState();

  // order
  const [order, setOrder] = useState({});

  // order form
  const [locations, setLocations] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [options, setOptions] = useState([]);
  const [cancelReasons, setCancelReasons] = useState([]);
  const [redirectURL, setRedirectURL] = useState("");

  // global loading indicator
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    const fetchTime = async () => {
      try {
        let request = await Api({
          method: "get",
          url: "settings/get-working-hours",
        });
        if (request?.data) {
          let data = request.data;
          setIsWithinWorkingHours(data?.is_within_working_hours);
          setFrom(data?.from);
          setTo(data?.to);
          setOrderTimeout(Number(data?.order_timeout));
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchTime();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      let response = await Api({
        method: "get",
        url: "settings/master-ds",
      });
      if (response?.data) {
        const {
          locations,
          redirect_url: redirectURL,
          vehicle_types: vehicleTypes,
          cancel_reasons: cancelReasons,
        } = response?.data;
        setLocations(locations);
        setVehicleTypes(vehicleTypes);
        console.log(`cancel reasons are `, cancelReasons);
        setCancelReasons(cancelReasons);
        setRedirectURL(redirectURL);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchOrder = () => {
      try {
        let order = fetch("order");
        if (order) {
          let createdAt = moment(order?.created_at);
          let now = moment();
          let diff = moment.duration(now.diff(createdAt));
          if (
            now.format("MM-DD-YYYY HH") == createdAt.format("MM-DD-YYYY HH")
          ) {
            if (diff.minutes() > Number(orderTimeout)) {
              remove("order");
            } else setOrder(order);
          }
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchOrder();
  }, [orderTimeout]);

  return (
    <AppContext.Provider
      value={{
        setIsLoading,
        setOptions,
        setOrder,
        isLoading,
        isWithinWorkingHours,
        from,
        to,
        vehicleTypes,
        locations,
        options,
        order,
        orderTimeout,
        cancelReasons,
        redirectURL,
      }}
    >
      <Container isLoading={isLoading}>{children}</Container>
    </AppContext.Provider>
  );
};

export default AppContext;
