import React from 'react';
import ReactCountdown, {zeroPad} from 'react-countdown';

const Countdown = ({
  orderTimeout,
  onComplete
}) => {

  const renderer = ({ hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  };

  return (
    <ReactCountdown
      date={orderTimeout}
      renderer={renderer}
      onComplete={onComplete}
    />
  )
}

export default Countdown;