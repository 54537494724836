export const store = (key, value) => {
  localStorage.setItem(`@${key}`, typeof value == 'object' ? JSON.stringify(value) : value)
}

export const remove = key => localStorage.removeItem(`@${key}`);

export const fetch = key => {
  let data = localStorage.getItem(`@${key}`);
  try {
    return JSON.parse(data);
  } catch (e) {
    if (e instanceof SyntaxError)
      return data
  }
}