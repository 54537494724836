import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const Spinner = ({
  isLoading
}) => {

  return (
    <BeatLoader color={'#61338c'} loading={isLoading} size={80} />
  )
}

export default Spinner;