import React from 'react';

import {useHistory} from 'react-router';

const ReOrder = ({

}) => {

  const history = useHistory();

  const goToOrder = () => history.push('/');

  return (
    <div className="container align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <i className="ion-ios-search search-icon text-muted"></i>
        <h4 className="text-muted">
          لا يوجد لديك طلب مركبة  
        </h4>
        <button onClick={goToOrder} className="btn btn-primary mt-3" id="custom-primary-btn">انشاء طلب</button>
      </div>
    </div>
  )
}

export default ReOrder;