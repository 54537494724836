import React from 'react';
import {AppContextProvider} from 'contexts/AppContext';

import Header from './components/header';
import axios from 'axios';
import {
    BrowserRouter as ReactRouter,
} from 'react-router-dom';
import Router from 'utils/router';

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL

const App = ({
    
}) => {

    return (
        <AppContextProvider>
            <ReactRouter>
                <Header />
                <Router />
            </ReactRouter>
        </AppContextProvider>
    );
}
    
export default App;