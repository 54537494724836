import React, { useContext, useState, useEffect } from "react";
import Api from "utils/api";
import { useHistory } from "react-router";
import AppContext from "contexts/AppContext";
import { store } from "utils/localStorage";
import ReCAPTCHA from "react-google-recaptcha";

const Order = ({}) => {
  const {
    locations,
    vehicleTypes,
    options,
    setOptions,
    setIsLoading,
    setOrder,
  } = useContext(AppContext);

  const history = useHistory();

  const [apiError, setApiError] = useState("");

  const [disableLocationSelect, setDisableLocationSelect] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [passengers, setPassengers] = useState("");
  const [errors, setErrors] = useState({});

  const [location, setLocation] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [duration, setDuration] = useState();
  const [total, setTotal] = useState();
  const [reCaptcha, setReCaptcha] = useState("");

  const updateOption = (optionId) => {
    setDuration(options?.find((i) => i.id == optionId).duration);
    setTotal(options?.find((i) => i.id == optionId).price);
  };

  const updateVehicleType = (vehicleTypeId) => {
    setVehicleType(vehicleTypeId);
    setOptions(vehicleTypes.find((item) => item.id == vehicleTypeId).options);
  };

  const validateField = (field) => {
    if (field == "mobile") {
      setErrors({ ...errors, mobile: mobile.length != 10 });
    }
    if (field == "passengers") {
      setErrors({ ...errors, passengers: passengers > 10 });
    }
    console.log(field, errors);
  };

  useEffect(() => {
    const checkLocation = async () => {
      setIsLoading(true);
      const params = new URL(window.location).searchParams;
      if (!params.get("location_id")) {
        setDisableLocationSelect(false);
      } else {
        let location = locations?.find(
          (item) => item.id == params.get("location_id")
        );
        setLocation(location?.id);
      }
      setIsLoading(false);
    };
    checkLocation();
  });

  const submit = async (e) => {
    try {
      e.preventDefault();
      setApiError("");
      let response = await Api({
        url: "/orders/store",
        method: "post",
        body: {
          name: name,
          mobile: mobile,
          passengers: passengers,
          duration: duration,
          location_id: location,
          vehicle_type_id: vehicleType,
          recaptcha: reCaptcha,
          total: total * passengers || total,
        },
      });
      if (response?.data?.order) {
        store("order", response?.data?.order);
        setOrder(response?.data?.order);
        history.push("/order/status");
      }
    } catch (e) {
      setApiError("حدث خطأ ما , الرجاء التأكد من الحقول");
    }
  };

  const onReCaptchaChange = (val) => {
    setReCaptcha(val);
  };

  return (
    <div className="container">
      <form onSubmit={submit} className="page-content" dir="rtl">
        {apiError ? (
          <div className="alert alert-danger text-right">{apiError}</div>
        ) : null}
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="name">الاسم</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="الاسم"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="mobile">رقم الجوال</label>
              <input
                type="text"
                name="mobile"
                className="form-control"
                onBlur={() => validateField("mobile")}
                placeholder="رقم الجوال"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              {errors?.mobile ? (
                <span className="text-danger d-block text-right">
                  رقم الجوال يجب ان يكون من ١٠ خانات
                </span>
              ) : (
                <span className="text-muted small d-block text-right">
                  ارقام انجليزية فقط
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="locations">الموقع</label>
              <select
                name="locations"
                disabled={disableLocationSelect}
                className="form-control"
                onChange={(e) => setLocation(e.target.value)}
              >
                <option>الموقع</option>
                {locations.map((item) => (
                  <option
                    selected={item.id == location ? "selected" : ""}
                    key={item.id}
                    value={item?.id}
                  >
                    {item?.display_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="vehicle_type_id">نوع المركبة</label>
              <select
                name="vehicle_type_id"
                className="form-control"
                onChange={(e) => updateVehicleType(e.target.value)}
              >
                <option>نوع المركبة</option>
                {vehicleTypes.map((vehicleType) => (
                  <option key={vehicleType.id} value={vehicleType?.id}>
                    {vehicleType?.display_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="duration">المدة</label>
              <select
                disabled={!vehicleType}
                name="duration"
                className="form-control"
                onChange={(e) => updateOption(e.target.value)}
              >
                <option>المدة</option>
                {options?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.duration} دقيقة
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label htmlFor="passengers">عدد الافراد</label>
              <input
                name="passengers"
                type="integer"
                className="form-control"
                placeholder="عدد الافراد"
                onBlur={() => validateField("passengers")}
                value={passengers}
                onChange={(e) => setPassengers(e.target.value)}
              />
              {errors?.passengers ? (
                <span className="text-danger d-block text-right">
                  اكبر عدد مسموح للركاب هو ١٠ اشخاص فقط
                </span>
              ) : (
                <span className="text-muted small d-block text-right">
                  ارقام انجليزية فقط
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md text-right mb-4">
            <span id="total">اجمالي: {total * passengers || total} ريال</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
              onChange={onReCaptchaChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="form-group text-right">
              <button
                className="btn btn-primary"
                id="custom-primary-btn"
                disabled={
                  !name ||
                  !mobile ||
                  mobile?.length != 10 ||
                  !passengers ||
                  !duration ||
                  !location ||
                  !vehicleType ||
                  !reCaptcha
                    ? true
                    : false
                }
              >
                ارسال الطلب
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Order;
