import React, {useState, useContext, useEffect} from 'react';

import Api from 'utils/api';
import AppContext from 'contexts/AppContext';
import ReactStars from "react-rating-stars-component";
import {useHistory} from 'react-router';

const RateOrder = () => {

  const history = useHistory();

  const {order: {id}} = useContext(AppContext);

  const [order, setOrder] = useState({});

  const [rate, setRate] = useState(0);

  const [message, setMessage] = useState('');

  useEffect(() => {
    const getOrder = async () => {
      try {
        if (id) {
          const request = await Api({
            method: 'get',
            url: `orders/order/${id}`
          });
          if (request?.data?.order)
            setOrder(request?.data?.order);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getOrder();
  }, []);

  const rateOrder = async e => {
    e.preventDefault();
    try {
      let request = await Api({
        method: 'post',
        url: `orders/order/rate`,
        body: {
          order_id: order?.id,
          rate,
          message
        }
      });
      console.log(request?.data)
      if (request?.data?.success)
        history.push('/success')
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="container">
      <div className="page-content text-right" dir="rtl">
        <form onSubmit={rateOrder}>
          <div className="mb-4">
            <h3 className="text-right">تقييمك للخدمة</h3>
            <span className="text-muted">عميلنا العزيز .. نرجوا منك تقييم الخدمة المقدمة لكم:</span>
          </div>
          <div className="form-group">
            <label htmlFor="">التعليق (اختياري)</label>
            <textarea rows="5" cols="10" placeholder="التعليق" className="form-control" onChange={e => setMessage(e.target.value)}></textarea>
          </div>
          <div className="my-2 d-flex align-items-center justify-content-center">
            <ReactStars
              dir="rtl"
              count={5}
              onChange={setRate}
              size={30}
              activeColor="#ffd700"
              classNames="no-outline"
            />
          </div>
          <div className="form-group">
            <button onClick={rateOrder} className="btn btn-primary" id="custom-primary-btn">ارسال</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RateOrder;